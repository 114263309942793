import React from 'react';
import CancelAppointmentForm from './components/CancelAppointmentForm';
import BookAppointmentForm from './components/BookAppointmentForm';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.appContainer}>
      {/* Header / Navbar */}
      <header className={styles.header}>
        <h1 className={styles.title}>Hair Design by Renata</h1>
        {/* Future: Login/Authentication button (placeholder) */}
        <button className={styles.loginButton} onClick={() => {/* TODO: implement login */}}>
          Login
        </button>
      </header>

      {/* Main Content Section */}
      <main className={styles.mainSection}>
        {/* Book Appointment Form Section */}
        <section className={styles.formSection}>
          <h2 className={styles.sectionTitle}>Book an Appointment</h2>
          <BookAppointmentForm />
        </section>

        {/* Cancel Appointment Form Section */}
        <section className={styles.formSection}>
          <h2 className={styles.sectionTitle}>Cancel Your Appointment</h2>
          <CancelAppointmentForm />
        </section>

        {/* Upcoming Appointments Section (placeholder content) */}
        <section className={styles.upcomingSection}>
          <h2 className={styles.sectionTitle}>Upcoming Appointments</h2>
          <p className={styles.placeholderText}>
            (Login to view your upcoming appointments)
          </p>
        </section>
      </main>
    </div>
  );
}

export default App;
