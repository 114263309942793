import React, { useState } from 'react';
import styles from './CancelAppointmentForm.module.css';

function CancelAppointmentForm() {
  // Form field state
  const [appointmentId, setAppointmentId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // Feedback state
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Replace with your actual API endpoint URL:
  const API_BASE_URL = 'https://hzn3mzppi5.execute-api.us-east-1.amazonaws.com/appointments';

  const handleCancelSubmit = async (e) => {
    e.preventDefault(); // prevent page reload
    setSuccessMessage('');
    setErrorMessage('');

    // Basic validation (ensure fields are not empty)
    if (!appointmentId || !name || !email) {
      setErrorMessage('Please fill out all fields.');
      return;
    }

    setLoading(true);
    try {
      // Construct the API endpoint (assuming appointmentId in path)
      const endpoint = `${API_BASE_URL}/${encodeURIComponent(appointmentId)}`;
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email })  // send name/email for verification
      });

      if (!response.ok) {
        // If server returned an error status
        let errorText = `Error ${response.status}`;
        try {
          // Attempt to extract error message from response
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorText = errorData.message;
          }
        } catch {
          // Response might not be JSON
        }
        throw new Error(errorText);
      }

      // If successful, you might receive a confirmation message
      // (Assume success if response.ok)
      setSuccessMessage('Your appointment has been canceled successfully.');
      // Optionally, clear the form fields on success
      setAppointmentId('');
      setName('');
      setEmail('');
    } catch (err) {
      // Network error or thrown error from response
      setErrorMessage(`Cancellation failed: ${err.message || 'Please try again.'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleCancelSubmit}>
      {/* Appointment ID Field */}
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="appointmentId">Appointment ID</label>
        <input
          id="appointmentId"
          type="text"
          className={styles.input}
          value={appointmentId}
          onChange={(e) => setAppointmentId(e.target.value)}
          placeholder="Enter your appointment ID"
          disabled={loading}
          required
        />
      </div>

      {/* Name Field */}
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          disabled={loading}
          required
        />
      </div>

      {/* Email Field */}
      <div className={styles.formGroup}>
        <label className={styles.label} htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          disabled={loading}
          required
        />
      </div>

      {/* Cancel Appointment Button */}
      <button type="submit" className={styles.cancelButton} disabled={loading}>
        {loading ? 'Cancelling...' : 'Cancel Appointment'}
      </button>

      {/* Feedback Messages */}
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </form>
  );
}

export default CancelAppointmentForm;
