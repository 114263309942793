import React, { useState } from 'react';
import styles from './BookAppointmentForm.module.css';

function BookAppointmentForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [datetime, setDatetime] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [appointmentId, setAppointmentId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!name || !email || !service || !datetime) {
      setError('Please fill out all fields.');
      return;
    }

    const emailPattern = /^\S+@\S+\.\S+$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://hzn3mzppi5.execute-api.us-east-1.amazonaws.com/appointments/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, service, datetime })
      });

      if (!response.ok) {
        let errorMsg = `Submission failed with status ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorMsg = errorData.message;
          }
        } catch {
          // Ignore JSON parsing error, use default message.
        }
        throw new Error(errorMsg);
      }

      const data = await response.json();
      const newId = data.appointmentId || data.id || '';
      setAppointmentId(newId);
    } catch (err) {
      // Corrected template literal: no backslashes before the backticks.
      setError(`Failed to book appointment. ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (appointmentId) {
    return (
      <div className={styles.form}>
        <div className={styles.successMessage}>
          ✅ Appointment booked successfully! Your appointment ID is <strong>{appointmentId}</strong>.
        </div>
      </div>
    );
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit} noValidate>
      {error && <div className={styles.errorMessage}>{error}</div>}

      <div className={styles.formGroup}>
        <label htmlFor="appointment-name" className={styles.label}>Name:</label>
        <input
          id="appointment-name"
          type="text"
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your name"
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="appointment-email" className={styles.label}>Email:</label>
        <input
          id="appointment-email"
          type="email"
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="you@example.com"
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="appointment-service" className={styles.label}>Service:</label>
        <select
          id="appointment-service"
          className={styles.input}
          value={service}
          onChange={(e) => setService(e.target.value)}
        >
          <option value="" disabled>Select a service</option>
          <option value="Women's Haircut">Women's Haircut</option>
          <option value="Men's Haircut">Men's Haircut</option>
          <option value="Seniors &amp; Children">Seniors &amp; Children</option>
          <option value="Full Weave">Full Weave</option>
          <option value="Partial Weave">Partial Weave</option>
          <option value="Cap Frosting">Cap Frosting</option>
          <option value="Color Correction">Color Correction</option>
          <option value="Conditioning Treatment">Conditioning Treatment</option>
          <option value="Shampoo &amp; Set">Shampoo &amp; Set</option>
          <option value="Blow Dry">Blow Dry</option>
          <option value="Flat Iron">Flat Iron</option>
          <option value="Up Do's">Up Do's</option>
          <option value="Perms">Perms</option>
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="appointment-datetime" className={styles.label}>Date and Time:</label>
        <input
          id="appointment-datetime"
          type="datetime-local"
          className={styles.input}
          value={datetime}
          onChange={(e) => setDatetime(e.target.value)}
        />
      </div>

      <button type="submit" className={styles.button} disabled={loading}>
        {loading ? 'Submitting…' : 'Book Appointment'}
      </button>
    </form>
  );
}

export default BookAppointmentForm;
